import {createContext} from "react";
import {EntityType} from "@common-core/coat-operational-hierarchy-appsync-model";

import {defaultTableState, TableState} from "./TableState";
import {SearchState} from "./SearchState";
import {SearchResult} from "../../entities/model";

export function createSearchStateContext() {
  return createContext<SearchState>({
    searchQuery: "",
    setSearchQuery(value: ((prevState: string) => string) | string): void {},
    entityType: undefined,
    setEntityType(
      value:
        | ((prevState: EntityType | undefined) => EntityType | undefined)
        | EntityType
        | undefined
    ): void {},
    tableState: defaultTableState(),
    setTableState(
      value: ((prevState: TableState) => TableState) | TableState
    ): void {},
    searchResult: undefined,
    setSearchResult(
      value:
        | ((prevState: SearchResult | undefined) => SearchResult | undefined)
        | SearchResult
        | undefined
    ): void {},
    resetSearchState() {}
  });
}
