import {FC, useState} from "react";
import {useTranslation} from "react-i18next";
import {
  SimpleTable,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from "@interstate/components/SimpleTable";
import {TabRow} from "../../tab-content";
import "./Sources.scss";
import {Source} from "@common-core/coat-operational-hierarchy-appsync-model";
import {OnCompleteCallback} from "../../utils";
import {EditSourcesModal} from "../operational-hierarchy/overview-tab";
import {EditButton, ActionButtons, CopyButton} from "../../action-buttons";
import {Typography} from "@interstate/components/Typography";

export interface SourceProps {
  id: string;
  sources: Source[] | undefined;
}

export const Sources: FC<SourceProps> = ({id, sources}) => {
  const {t} = useTranslation();
  const [editingSources, setEditingSources] = useState<boolean>(false);
  const finishSourcesEditing: OnCompleteCallback = () => {
    setEditingSources(false);
  };

  return (
    <TabRow
      name={"sources"}
      header={
        <h4>
          {t("entity-detail.dealer.sources.details")}
          {(!sources || sources.length === 0) && (
            <EditButton
              onClick={() => {
                setEditingSources(true);
              }}
              qualifier={"source"}
              tooltip={t("entity-detail.dealer.sources.edit-all")}
              inline={false}
            />
          )}
        </h4>
      }>
      {editingSources && (
        <EditSourcesModal
          id={id}
          initalSources={!sources || sources.length === 0 ? [] : sources}
          onComplete={finishSourcesEditing}
        />
      )}
      {sources && sources.length > 0 ? (
        <SimpleTable
          id={`source-table-${id}`}
          data-testid={`source-table-${id}`}
          dataDensity={"small"}
          className={"source-table"}
          background={"white"}
          hover={false}>
          <TableHead>
            <TableRow>
              <TableCell className={"source-type"}>
                {t("entity-detail.dealer.sources.type")}
              </TableCell>
              <TableCell className={"source-id"}>
                {t("entity-detail.dealer.sources.id")}
              </TableCell>
              <TableCell className={"source-actions"} />
            </TableRow>
          </TableHead>
          <TableBody>
            {sources.map((source: Source) => (
              <TableRow key={`${source.sourceType}|${source.sourceId}`}>
                <TableCell className={"source-type"}>
                  {source.sourceType}
                </TableCell>
                <TableCell className={"source-id"}>{source.sourceId}</TableCell>
                <TableCell className={"source-actions"}>
                  <ActionButtons>
                    <CopyButton
                      qualifier={source.sourceId}
                      item={t("entity-detail.dealer.sources.id")}
                      content={source.sourceId}
                      inline={true}
                    />
                    <EditButton
                      onClick={() => {
                        setEditingSources(true);
                      }}
                      qualifier={source.sourceId}
                      tooltip={t("entity-detail.dealer.sources.edit-one")}
                      inline={true}
                    />
                  </ActionButtons>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </SimpleTable>
      ) : (
        <Typography tag={"span"} className={"no-sources"} variant={"body-sm"}>
          {t("entity-detail.dealer.sources.none")}
        </Typography>
      )}
    </TabRow>
  );
};
