import {FC} from "react";
import {HomePageTabSection} from "./HomePageTabSection";
import {useRecentlyViewed} from "../hooks";
import {useTranslation} from "react-i18next";

export const RecentlyViewedTabSection: FC = () => {
  const {browsedDealers} = useRecentlyViewed();
  const {t} = useTranslation();

  return (
    <HomePageTabSection
      id={"recently-viewed-tab-section"}
      title={t("homepage.recently-viewed.title")}
      favorites={browsedDealers}
      emptyText={t("homepage.recently-viewed.empty-text")}
      timeDescriptor={t("homepage.recently-viewed.time-descriptor")}
      sectionName={"recently-viewed"}
    />
  );
};
