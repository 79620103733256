import {useCallback} from "react";
import {Partition} from "@common-core/runtime-js/partition";
import {useRuntime} from "@common-core/react-runtime/context";
import {Metadata} from "../../runtime";
import {useStickyState} from "../../hooks";

export interface Favorite {
  id: string;
  ts: number;
}

export type FavoriteFunction<R> = (entityId: string) => R;

export interface Favorites {
  favorites: Favorite[];
  addFavorite: FavoriteFunction<void>;
  removeFavorite: FavoriteFunction<void>;
  isFavorite: FavoriteFunction<boolean>;
  toggleFavorite: FavoriteFunction<boolean>;
}

export function useFavorites(): Favorites {
  const runtime = useRuntime<Metadata>();
  const key =
    runtime.partition === Partition.PROD
      ? "favoriteDealersProd"
      : "favoriteDealersNonProd";

  const [favorites, setFavorites] = useStickyState<Favorite[]>([], key);

  const addFavorite = useCallback(
    (entityId: string): void =>
      setFavorites(
        favorites.concat([
          {
            id: entityId,
            ts: Date.now()
          }
        ])
      ),
    [favorites, setFavorites]
  );
  const removeFavorite = useCallback(
    (entityId: string): void =>
      setFavorites(
        favorites.filter((fav: Favorite) => {
          return fav.id !== entityId;
        })
      ),
    [favorites, setFavorites]
  );

  const isFavorite = useCallback(
    (entityId: string): boolean =>
      favorites.filter((fav: Favorite) => fav.id === entityId).length !== 0,
    [favorites]
  );
  const toggleFavorite = useCallback(
    (entityId: string): boolean => {
      const added = !isFavorite(entityId);
      added ? addFavorite(entityId) : removeFavorite(entityId);
      return added;
    },
    [isFavorite, removeFavorite, addFavorite]
  );

  return {
    favorites,
    addFavorite,
    removeFavorite,
    isFavorite,
    toggleFavorite
  };
}
