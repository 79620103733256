import {FC} from "react";
import {useFlags} from "launchdarkly-react-client-sdk";
import {features} from "../features";
import {OperationalHomePage} from "./operational-hierarchy";
import {HomePage} from "./HomePage";

export const HomePageSelector: FC = () => {
  const flags = useFlags();
  if (flags[features.pdt.oe.viewOperationalEntity]) {
    return <OperationalHomePage />;
  } else {
    return <HomePage />;
  }
};
