import {createContext} from "react";
import {EntityType} from "@common-core/coat-operational-hierarchy-appsync-model";
import {OperationalSearchResult} from "../../../model";
import {defaultTableState, TableState} from "../../../../search/context";
import {OperationalSearchState} from "./OperationalSearchState";

export function createOperationalSearchStateContext() {
  return createContext<OperationalSearchState>({
    searchQuery: "",
    setSearchQuery(value: ((prevState: string) => string) | string): void {},
    entityType: undefined,
    setEntityType(
      value:
        | ((prevState: EntityType | undefined) => EntityType | undefined)
        | EntityType
        | undefined
    ): void {},
    tableState: defaultTableState(),
    setTableState(
      value: ((prevState: TableState) => TableState) | TableState
    ): void {},
    searchResult: undefined,
    setSearchResult(
      value:
        | ((
            prevState: OperationalSearchResult | undefined
          ) => OperationalSearchResult | undefined)
        | OperationalSearchResult
        | undefined
    ): void {},
    resetSearchState() {}
  });
}
