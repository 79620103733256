import {FC, ReactNode} from "react";
import {OldBusinessInformation} from "./OldBusinessInformation";

import "./OldDetailsColumn.scss";
import {OldCommonOrgDealerStatusBar} from "./OldCommonOrgDealerStatusBar";
import {useOldCommonOrgEntity} from "../../context";
import {Address, Oems} from "../../overview";
import {TabColumn} from "../../../tab-content";
import {EntitySourceType} from "../../EntitySourceType";

interface OldDetailsColumnProps {
  detailsColumnHeader: ReactNode;
  entitySourceType: EntitySourceType;
}
export const OldDetailsColumn: FC<OldDetailsColumnProps> = ({
  detailsColumnHeader,
  entitySourceType
}) => {
  const {
    entity: {
      id,
      dealerInformation: {address, primaryPhone},
      oems,
      statuses
    }
  } = useOldCommonOrgEntity();

  return (
    <TabColumn name={"details-column"} header={detailsColumnHeader}>
      <OldCommonOrgDealerStatusBar
        statuses={statuses || []}
        id={id}
        entitySourceType={entitySourceType}
      />
      <OldBusinessInformation />
      <Address
        id={id}
        streetAddressLine1={address?.street}
        streetAddressLine2={address?.streetAddressLine2}
        city={address?.city}
        state={address?.state}
        country={address?.country}
        zip={address?.zip}
        telecomNumbers={(primaryPhone ?? "").split(",")}
      />
      <Oems id={id} oems={oems} />
    </TabColumn>
  );
};
