import {
  EntityStatus,
  EntityType,
  Oem
} from "@common-core/coat-operational-hierarchy-appsync-model";
import {LinkedSolutions, OldCommonOrgEntity} from "../../entities/model";

export interface TableData {
  key: string;
  name: string;
  city: string;
  state: string;
  oems: Oem[];
  solutions: LinkedSolutions;
  type: EntityType;
  statuses: EntityStatus[];
}

/**
 * Flattens the entity into structure compatible with
 * the CX Table sorting capabilities
 */
export function toTableData(entity: OldCommonOrgEntity): TableData {
  return {
    key: entity.id,
    name: entity.dealerInformation.dbaName,
    city: entity.dealerInformation?.address?.city || "",
    state: entity.dealerInformation?.address?.state || "",
    oems: entity.oems || [],
    solutions: filterTypeName(entity.linkedSolutions),
    type: entity.entityType,
    statuses: entity.statuses || []
  };
}

/**
 * Filters out the typename returned with all apollo graphql queries.
 * .reduce builds a new object from the filtered array linkedSolutions and returns it
 * */

function filterTypeName(linkedSolutions: LinkedSolutions): any {
  return Object.keys(linkedSolutions)
    .filter(solution => solution != "__typename")
    .reduce((obj, solution) => {
      return {
        ...obj,
        [solution]: linkedSolutions[solution]
      };
    }, {});
}
