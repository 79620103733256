import {useContext} from "react";
import {SearchState} from "./SearchState";
import {EntitySourceType} from "../../entities";
import {OldCommonOrgSearchStateContext} from "../old-common-org-search";

// TODO: convert this to a Client-specific hook once real OE search is implemented.
export function useSearchState(
  entitySourceType: EntitySourceType
): SearchState {
  return useContext(OldCommonOrgSearchStateContext);
}
