import {
  ReactElement,
  FC,
  ReactNode,
  PropsWithChildren,
  MouseEventHandler
} from "react";
import {useTranslation} from "react-i18next";
import {Card} from "@interstate/components/Card";
import {HomeIcon} from "@interstate/components/Icons/HomeIcon";
import {CarSolidIcon} from "@interstate/components/Icons/CarSolidIcon";
import {
  EntityType,
  entityTypeKey,
  EntityStatus
} from "@common-core/coat-operational-hierarchy-appsync-model";
import {EntityStatusBar} from "../badges";
import {Heading} from "./Heading";
import {EntityCardHeader} from "./EntityCardHeader";
import {EntityData} from "./EntityData";
import "./EntityCard.scss";

export interface EntityCardProps {
  id: string;
  className: string;
  heading?: Heading;
  entity: EntityData;
  footer?: ReactNode;
  onClick?: MouseEventHandler;
}

export function icon(type: EntityType): ReactElement {
  switch (type) {
    case EntityType.GROUP:
    case EntityType.SUB_GROUP: {
      return (
        <HomeIcon
          id={`entity-type-icon-${entityTypeKey(type)}`}
          data-testid={`entity-type-icon-${entityTypeKey(type)}`}
          className={"entity-type-icon"}
        />
      );
    }
    case EntityType.ENTITY:
    case EntityType.STANDALONE_ENTITY: {
      return (
        <CarSolidIcon
          id={`entity-type-icon-${entityTypeKey(type)}`}
          data-testid={`entity-type-icon-${entityTypeKey(type)}`}
          className={"entity-type-icon"}
        />
      );
    }
  }
}

export const EntityCard: FC<PropsWithChildren<EntityCardProps>> = ({
  id,
  className,
  heading = "h4",
  entity,
  footer,
  onClick = entity => {},
  children
}) => {
  const {t} = useTranslation();
  return (
    <Card
      id={id}
      data-testid={id}
      className={`entity-card ${className}`}
      header={
        <>
          <EntityCardHeader heading={heading} entity={entity} />
          <div className={"entity-card-entity-type"}>
            {icon(entity.entityType)}
            <span className={"entity-type-label"}>
              {t(`entity-detail.types.${entityTypeKey(entity.entityType)}`)}
            </span>
          </div>
        </>
      }
      content={
        <>
          <EntityStatusBar
            id={entity.id}
            statuses={
              entity.statuses?.filter(
                status => status !== EntityStatus.PROTECTED
              ) || []
            }
            compact={false}
          />
          <div className={"entity-card-body"}>{children}</div>
        </>
      }
      footer={footer && <div className={"entity-card-footer"}>{footer}</div>}
      primaryAction={event => onClick(event)}
    />
  );
};
