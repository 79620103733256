import {useRuntime} from "@common-core/react-runtime/context";
import {Metadata} from "../runtime";
import {Partition} from "@common-core/runtime-js/partition";
import {useStickyState} from "./useSitckyState";
import {useCallback} from "react";
import {Favorite} from "../entities/favorites/useFavorites";

const MAX_HISTORY = 12;

export interface BrowsedDealers {
  browsedDealers: Favorite[];
  addBrowsedDealer: RecentlyViewedFunction<void>;
}
export type RecentlyViewedFunction<R> = (entityId: string) => R;

export function useRecentlyViewed(): BrowsedDealers {
  const runtime = useRuntime<Metadata>();
  const key =
    runtime.partition === Partition.PROD
      ? "browsedDealersProd"
      : "browsedDealersNonProd";

  const [browsedDealers, setBrowsedDealers] = useStickyState<Favorite[]>(
    [],
    key
  );

  const addBrowsedDealer = useCallback(
    (entityId: string): void => {
      const dealers = browsedDealers.filter(fav => fav.id !== entityId);

      dealers.push({
        id: entityId,
        ts: Date.now()
      });
      while (dealers.length > MAX_HISTORY) {
        dealers.shift();
      }
      setBrowsedDealers(dealers);
    },
    [browsedDealers, setBrowsedDealers]
  );

  return {
    browsedDealers,
    addBrowsedDealer
  };
}
