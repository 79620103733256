import {useEffect, useState} from "react";
import {EntityType} from "@common-core/coat-operational-hierarchy-appsync-model";
import {SearchResult} from "../../entities/model";
import {defaultTableState, TableState} from "./TableState";

export function useDefaultSearchState(
  query: string | undefined,
  type: EntityType | undefined,
  table: TableState | undefined,
  result: SearchResult | undefined
) {
  const [searchQuery, setSearchQuery] = useState<string>(() => query || "");
  const [entityType, setEntityType] = useState<EntityType | undefined>(type);
  const [tableState, setTableState] = useState<TableState>(
    () => table || defaultTableState()
  );
  const [searchResult, setSearchResult] = useState<SearchResult | undefined>(
    () => result
  );

  // Resets the search state to the initial values
  const resetSearchState = (): void => {
    setSearchQuery(query || "");
    setTableState(table || defaultTableState());
    setSearchResult(result);
    setEntityType(type);
  };

  useEffect(resetSearchState, [query, type, table, result]);

  return {
    searchQuery,
    setSearchQuery,
    entityType,
    setEntityType,
    tableState,
    setTableState,
    searchResult,
    setSearchResult,
    resetSearchState
  };
}
