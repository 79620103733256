import {FC, MouseEventHandler} from "react";
import "./HierarchyNodeLabel.scss";
import {HierarchyNode} from "./HierarchyNode";
import {HierarchyNodePopover} from "./HierarchyNodePopover";

export interface HierarchyNodeLabelProps {
  node: HierarchyNode;
  onClick?: MouseEventHandler;
}

export const HierarchyNodeLabel: FC<HierarchyNodeLabelProps> = ({
  node,
  onClick = event => {}
}) => {
  const {dealerInformation} = node;

  return (
    <HierarchyNodePopover node={node}>
      <div
        id={`hierarchy-node-label-${node.id}`}
        data-testid={`hierarchy-node-label-${node.id}`}
        className={"hierarchy-node-label"}
        onClick={onClick}>
        <div className={"dbaname"}>{dealerInformation?.dbaName}</div>
        <div className={"address-line"}>
          {dealerInformation?.address?.street +
            ", " +
            (dealerInformation?.address?.streetAddressLine2
              ? dealerInformation?.address?.streetAddressLine2 + ", "
              : "") +
            dealerInformation?.address?.city +
            ", " +
            dealerInformation?.address?.state}
        </div>
      </div>
    </HierarchyNodePopover>
  );
};
