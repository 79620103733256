/* eslint-disable react-hooks/exhaustive-deps */
import {FC, ReactElement, useEffect, useState} from "react";
import {TreeItem, TreeView} from "@mui/x-tree-view";
import {useInterstateToken} from "@interstate/components/InterstateThemeProvider";
import {
  ChevronDownIcon,
  ChevronRightIcon,
  ArrowLongRightIcon
} from "@interstate/components/Icons";
import {Spinner} from "@interstate/components/Spinner";
import {useToastErrorHandler} from "../../../backend";
import {useNavigation} from "../../../hooks";
import {TabColumn} from "../../../tab-content";
import {useOldCommonOrgEntity} from "../../context";
import {
  HierarchyNode,
  collectSelectionPath,
  HierarchyNodeLabel,
  HierarchyNodeResponse
} from "../../hierarchy";
import "./OldCommonOrgHierarchyColumn.scss";
import {useLazyQuery} from "@apollo/client";
import {LookupOldCommonOrgHierarchy} from "../../backend";
import {EntitySourceType} from "../../EntitySourceType";
import {BackendError} from "@common-core/runtime-js/backend";
import {Endpoints} from "../../../runtime";

export const OldCommonOrgHierarchyColumn: FC = () => {
  const toastError = useToastErrorHandler();
  const token = useInterstateToken();
  const [expanded, setExpanded] = useState<string[]>(() => []);
  const [root, setRoot] = useState<HierarchyNode>();
  const {
    entity: {id}
  } = useOldCommonOrgEntity();
  const {navigateToEntity} = useNavigation();

  const [lookupRoot] = useLazyQuery<HierarchyNodeResponse>(
    LookupOldCommonOrgHierarchy,
    {
      fetchPolicy: "no-cache"
    }
  );

  const renderTree = (node: HierarchyNode): ReactElement => {
    const selected = node.id === id;
    const children = node.children ? [...node.children] : [];
    return (
      <TreeItem
        key={node.id}
        nodeId={node.id}
        data-testid={`hierarchy-node-${node.id}`}
        className={`hierarchy-node${selected ? " hierarchy-node--selected" : ""}`}
        icon={
          selected ? (
            <ArrowLongRightIcon color={token("sem.color.on-surface.primary")} />
          ) : undefined
        }
        label={
          <HierarchyNodeLabel
            node={node}
            onClick={event => {
              navigateToEntity(node.id, EntitySourceType.OLD);
            }}
          />
        }>
        {children
          ?.sort((a, b) => {
            if (expanded.includes(a.id)) {
              return -1;
            }
            if (expanded.includes(b.id)) {
              return 1;
            }
            return a.dealerInformation.dbaName.localeCompare(
              b.dealerInformation.dbaName
            );
          })
          .map(child => renderTree(child))}
      </TreeItem>
    );
  };

  useEffect(() => {
    lookupRoot({
      variables: {id},
      context: {
        endpoint: Endpoints.BACKEND
      }
    })
      .then(result => {
        if (!result.data?.hierarchyNode) {
          throw new BackendError(
            `Root hierarchy node of entity ${id} not found`,
            404
          );
        }
        return result.data.hierarchyNode;
      })
      .then(root => {
        const expanded: string[] = [];
        if (collectSelectionPath(root, id, expanded)) {
          setExpanded(expanded);
          setRoot(root);
        }
      })
      .catch(toastError);
  }, []);

  if (root === undefined || expanded.length === 0) {
    return (
      <TabColumn name={"hierarchy-column"}>
        <Spinner />
      </TabColumn>
    );
  }

  return (
    <TabColumn name={"hierarchy-column"}>
      <TreeView
        id={`hierarchy-tree-view-${root?.id}`}
        data-testid={`hierarchy-tree-view-${root?.id}`}
        className={"hierarchy-tree-view"}
        defaultCollapseIcon={<ChevronDownIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        defaultExpanded={expanded}
        defaultSelected={id}>
        {renderTree(root)}
      </TreeView>
    </TabColumn>
  );
};
