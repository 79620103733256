import {FC} from "react";
import {IconType} from "../../lib/interstate";
import "./DealerStatusBarItem.scss";

export interface DealerStatusBarItemProps {
  type: string;
  value: string;
  icon: IconType;
  label: string;
}

export const DealerStatusBarItem: FC<DealerStatusBarItemProps> = ({
  type,
  value,
  icon,
  label
}) => {
  const Icon = icon;
  return (
    <div
      id={`dealer-status-bar-${type}-${value}`}
      data-testid={`dealer-status-bar-${type}-${value}`}
      className={`dealer-status-bar-item dealer-status-bar-${type}`}>
      <Icon
        id={`dealer-status-bar-icon-${type}-${value}`}
        className={`dealer-status-bar-icon dealer-status-bar-icon-${type}`}
      />
      <span className={"dealer-status-bar-label"}>{label}</span>
    </div>
  );
};
