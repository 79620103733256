import {TabBody} from "../../../tab-content";
import {HashTab} from "../../../hash-tabs";

import "./OldOverviewTab.scss";
import {useOldCommonOrgEntity} from "../../context";

import {OldDetailsColumn} from "./OldDetailsColumn";

import {useTranslation} from "react-i18next";
import {DetailsColumnHeader, Solutions} from "../../overview";
import {HistoryTabColumn} from "../../history";
import {EntitySourceType} from "../../EntitySourceType";

export const OldOverviewTab: HashTab<{
  displayHistory?: boolean;
  entitySourceType: EntitySourceType;
}> = ({displayHistory, entitySourceType}) => {
  const {
    entity: {id, linkedSolutions}
  } = useOldCommonOrgEntity();

  const {t} = useTranslation();
  return (
    <TabBody name={"overview"}>
      <OldDetailsColumn
        entitySourceType={entitySourceType}
        detailsColumnHeader={
          <DetailsColumnHeader
            label={t(detailsHeader(entitySourceType))}
            id={id}
          />
        }
      />
      <Solutions linkedSolutions={linkedSolutions} />
      {displayHistory && <HistoryTabColumn id={id} />}
    </TabBody>
  );
};

const detailsHeader = (entitySourceType: EntitySourceType) => {
  return "entity-detail.dealer.details";
};
