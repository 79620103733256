import {FC} from "react";
import {useFavorites} from "../entities/favorites";
import {HomePageTabSection} from "./HomePageTabSection";
import {useTranslation} from "react-i18next";
export const FavoritesTabSection: FC = () => {
  const {favorites} = useFavorites();
  const {t} = useTranslation();

  return (
    <HomePageTabSection
      id={"favorites-tab-section"}
      title={t("homepage.favorites.title")}
      favorites={favorites}
      emptyText={t("homepage.favorites.empty-text")}
      timeDescriptor={t("homepage.favorites.time-descriptor")}
      sectionName={"favorites"}
    />
  );
};
