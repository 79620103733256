/* eslint-disable react-hooks/exhaustive-deps */
// React
import {FC, useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useAnalytics} from "@common-core/react-analytics";
// Hooks
import {useNavigation} from "../../hooks";

// CX Components
import {SortableDetailsType, Table} from "@interstate/components/Table";

// Project
import "../Search.scss";
import {Pagination, Sort, TableState, useSearchState} from "../context";
import {TableData, toTableData, useOldCommonOrgTableColumns} from "../table";
import {EmptyHashTab, HashTabs} from "../../hash-tabs";
import {entityTypeToHash, hashToEntityType} from "../EntityTabMapper";
import {SearchBar} from "../search-bar";
import {EntitySourceType} from "../../entities";
import type {GetComponentProps} from "rc-table/lib/interface";
import {useLazyQuery} from "@apollo/client";
import {
  SearchOldCommonOrgEntities,
  SearchResponse
} from "../../entities/backend";
import {Endpoints} from "../../runtime";

export interface SearchProps {
  query?: string;
  entitySourceType: EntitySourceType;
}

// TODO: Remove this component once new Client Search and new Operational Search are implemented.
export const OldCommonOrgEntitySearch: FC<SearchProps> = ({
  query,
  entitySourceType
}) => {
  const {t} = useTranslation();
  const {
    searchQuery,
    setSearchQuery,
    entityType,
    setEntityType,
    tableState,
    setTableState,
    searchResult,
    setSearchResult,
    resetSearchState
  } = useSearchState(entitySourceType);
  const [initialRender, setInitialRender] = useState(true);
  const [searchValue, setSearchValue] = useState(query || searchQuery);
  const [search, {loading, error, data}] = useLazyQuery<SearchResponse>(
    SearchOldCommonOrgEntities
  );
  const {navigateToEntityFromEvent} = useNavigation();
  const columns = useOldCommonOrgTableColumns();
  const {productEvent} = useAnalytics();

  const saveTablePaginationState = (pagination: Pagination): void => {
    setTableState((current: TableState) => {
      return {
        ...current,
        pagination
      };
    });
  };
  const saveTableSortState = (sort: Sort): void => {
    setTableState((current: TableState) => {
      return {
        ...current,
        sort
      };
    });
  };

  const hasSearchResults = (): boolean => {
    return !!searchResult && searchResult.results.length > 0;
  };

  const onQueryChange = (query: string): void => {
    setSearchValue(query);
  };

  const onQueryCleared = (): void => {
    setSearchValue("");
    resetSearchState();
  };

  const onSearch = (event: any, query: string): void => {
    setSearchValue(query);
    // This messes up search if written as variables:{query: {query}}
    search({
      variables: {query},
      context: {
        endpoint: Endpoints.BACKEND
      }
    });
  };

  const onSearchResultInteraction: GetComponentProps<TableData> = record => {
    return {
      onClick: (event: any) => {
        productEvent({
          name: "dealer_selected",
          properties: {
            location: `${t(
              `analytics.location.${entitySourceType}`
            )} Search Results`,
            value: `${record.key}`,
            result: "Dealer Details Displayed"
          }
        });
        navigateToEntityFromEvent(
          event,
          record.key,
          entitySourceType,
          "/search"
        );
      }
    };
  };

  /**
   * This effect monitors changes to the search result which will be
   * updated asynchronously after the search is initiated. When the
   * result is updated internally by the entity search hook, this
   * effect will run. The dependency list reflects the fact that
   * this effect only cares about changes to the result.
   */
  useEffect(() => {
    // When there are results, save to search state
    if (data) {
      setSearchQuery(searchValue);
      setSearchResult(data.searchResult);
    }
  }, [data, error]);

  /**
   * This effect monitors changes to the search value and the filters
   * and initiates a search when they change.
   */
  useEffect(() => {
    // If this is the first render and there are cached search results,
    // do not initiate a search
    try {
      const displayCachedResults =
        initialRender && hasSearchResults() && searchValue === searchQuery;
      if (!displayCachedResults && searchValue.trim()) {
        // I dont know why this requires the query key and the other call doesn't
        // Could it be because its in a useEffect hook?
        search({variables: {query: searchValue}});
      }
    } finally {
      setInitialRender(false);
    }
  }, []);

  const tableData = useMemo<TableData[]>(() => {
    if (entityType) {
      return (
        searchResult?.results
          .filter(entity => entity.entityType === entityType)
          .map(toTableData) || []
      );
    } else {
      return searchResult?.results.map(toTableData) || [];
    }
  }, [searchResult, entityType]);

  return (
    <div className="entity-search" data-testid={"client-entity-search"}>
      <div className="entity-search-controls">
        <SearchBar
          id={"entity-search-box"}
          query={searchValue}
          onQueryCleared={onQueryCleared}
          onQueryChange={onQueryChange}
          onSearch={onSearch}
          analyticsLocation={entitySourceType}
        />
      </div>
      {entitySourceType === EntitySourceType.OPERATIONAL && (
        <HashTabs
          id={"entity-type-filter-tabs"}
          initial={entityTypeToHash(entityType)}
          onTabActivated={({tab}) => {
            setEntityType(hashToEntityType(tab));
            productEvent({
              name: "types_filtered",
              properties: {
                location: `${t(
                  `analytics.location.${entitySourceType}`
                )} Search Results`,
                value: t(
                  `entity-detail.types.${hashToEntityType(tab)?.toLowerCase()}`,
                  {lng: "en"}
                ),
                result: "Results Filtered by Type"
              }
            });
          }}>
          <EmptyHashTab
            label={t("entity-types.all-results")}
            hash="all-results"
          />
          <EmptyHashTab
            label={t("entity-types.dealerships")}
            hash="dealerships"
          />
          <EmptyHashTab
            label={t("entity-types.single-dealers")}
            hash="single-dealers"
          />
          <EmptyHashTab
            label={t("entity-types.dealer-groups")}
            hash="dealer-groups"
          />
          <EmptyHashTab
            label={t("entity-types.sub-groups")}
            hash="sub-groups"
          />
        </HashTabs>
      )}
      <Table
        id="entity-table"
        data-testid="entity-table"
        onChangePageSize={size => {
          saveTablePaginationState({
            page: tableState.pagination.page,
            size
          });
        }}
        onChangePage={page => {
          saveTablePaginationState({
            page,
            size: tableState.pagination.size
          });
        }}
        onChangeSorter={(sorter: any) => {
          productEvent({
            name: "table_column_sorted",
            properties: {
              value: `${sorter.field}`,
              location: `${t(
                `analytics.location.${entitySourceType}`
              )} Search Results`,
              result: `Columns Sorted ${sorter.order}`
            }
          });
          saveTableSortState({
            column: sorter.field,
            order: sorter.order
          });
        }}
        onRowCallback={onSearchResultInteraction}
        emptyText={
          searchQuery
            ? t("entity-search.no-results") || "???"
            : t("entity-search.suggestion") || "???"
        }
        isLoading={loading}
        sortableColumns={true}
        sortableDetails={
          {
            activeSortColumn: tableState.sort.column,
            sortOrder: tableState.sort.order,
            // The list of sortable columns is dictated by which columns in the column definition have a sorter attached,
            // but this sortableColumns attribute must be supplied in order to prevent all columns from being sortable.
            sortableColumns: []
          } as SortableDetailsType
        }
        columns={columns}
        data={tableData}
        enablePagination={true}
        paginationCurrent={tableState.pagination.page}
        displayPageSizeSelector={true}
        defaultPageSize={10}
        currentPageSize={tableState.pagination.size}
        paginationPageSizeOptions={[10, 20, 50, 100]}
        pageSizePosition={"bottom"}
        bordered={true}
        borderType={"contained"}
        size={"small"}
        dataDensity={"small"}
        highlightOnHover={true}
      />
    </div>
  );
};
