import {FC} from "react";
import {useTranslation} from "react-i18next";
import {OperationalEntityPage} from "./OperationalEntityPage";
import {useFlags} from "launchdarkly-react-client-sdk";
import {features} from "../../features";
import {OldCommonOrgEntityPage} from "../old-common-org";

export const OperationalEntityPageSelector: FC = () => {
  const flags = useFlags();
  const {t} = useTranslation();
  if (flags[features.pdt.oe.viewOperationalEntity]) {
    return <OperationalEntityPage />;
  } else {
    return <OldCommonOrgEntityPage />;
  }
};
