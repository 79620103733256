import {FC} from "react";
import {useTranslation} from "react-i18next";
import {useAnalytics} from "@common-core/react-analytics";
import {useInterstateToken} from "@interstate/components/InterstateThemeProvider";
import {Button} from "@interstate/components/Button";
import {
  StarIcon,
  StarSolidIcon,
  CheckCircleIcon
} from "@interstate/components/Icons";
import {useToaster} from "@interstate/components/Toast";
import {useOldCommonOrgEntity} from "../context";
import {useFavorites} from "./useFavorites";
import "./FavoriteButton.scss";

export const FavoriteButton: FC = () => {
  const {t} = useTranslation();
  const {toast} = useToaster();
  const {
    entity: {id, dealerInformation}
  } = useOldCommonOrgEntity();
  const {isFavorite, toggleFavorite} = useFavorites();
  const favorite = isFavorite(id);
  const {productEvent} = useAnalytics();
  const token = useInterstateToken();

  return (
    <Button
      id={`entity-favorite-button-${id}`}
      data-testid={`entity-favorite-button-${id}`}
      className={"entity-favorite-button"}
      buttonStyle={"tertiary"}
      icon={
        favorite ? (
          <StarSolidIcon
            id={`entity-favorite-icon-${id}-favorite`}
            data-testid={`entity-favorite-icon-${id}-favorite`}
            className={"entity-favorite-icon entity-favorite-icon--favorite"}
          />
        ) : (
          <StarIcon
            id={`entity-favorite-icon-${id}-not-favorite`}
            data-testid={`entity-favorite-icon-${id}-not-favorite`}
            className={
              "entity-favorite-icon entity-favorite-icon--not-favorite"
            }
          />
        )
      }
      onClick={() => {
        const added = toggleFavorite(id);
        productEvent({
          name: "favorite_toggled",
          properties: {
            location: "Dealer Details Overview",
            value: `${id}`,
            result: `${added ? "Set" : "Unset"}`
          }
        });
        if (dealerInformation.dbaName) {
          toast(
            "Favorites",
            added
              ? t("favorites.added", {item: dealerInformation.dbaName})
              : t("favorites.removed", {
                  item: dealerInformation.dbaName
                }),
            undefined,
            <CheckCircleIcon
              height={24}
              width={24}
              color={token("sem.color.border.success")}
            />
          );
        }
      }}
    />
  );
};
