import "./HistoryComponentWrapper.scss";
import {TabColumn, TabRow} from "../../tab-content";
import {useTranslation} from "react-i18next";
import {FC} from "react";
import {useEnvironment} from "@common-core/react-runtime/context";
import {EmbeddedComponentLoader} from "../embedded";
import {Environment} from "../../runtime";

export const HistoryTabColumn: FC<{id: string}> = ({id}) => {
  const {t} = useTranslation();
  const environment = useEnvironment<Environment>();

  return (
    <TabColumn
      name={"history"}
      header={<h3>{t("entity-detail.history.details")}</h3>}>
      <TabRow name={"history"}>
        <EmbeddedComponentLoader
          definition={environment.historyComponent}
          entity={id}
        />
      </TabRow>
    </TabColumn>
  );
};
