import {TabBody} from "../tab-content";
import ReactTimeAgo from "react-timeago";
import "./HomePageTab.scss";
import {EntityType} from "@common-core/coat-operational-hierarchy-appsync-model";
import {EntityCard} from "../entities/card";
import {HashTabProps, HashTab} from "../hash-tabs";
import {useNavigation} from "../hooks";
import {EntityWithTimestamp} from "./HomePageTabSection";
import {ClockIcon} from "@interstate/components/Icons";
import {useTranslation} from "react-i18next";
import {useAnalytics} from "@common-core/react-analytics";

export interface HomePageTabProps extends HashTabProps {
  entityType?: EntityType;
  entities?: EntityWithTimestamp[];
  timeDescriptor: string;
  sectionName: string;
}
export const HomePageTab: HashTab<HomePageTabProps> = ({
  entityType,
  entities = [],
  timeDescriptor,
  hash,
  sectionName
}: HomePageTabProps) => {
  const {navigateToEntityFromEvent} = useNavigation();
  const {productEvent} = useAnalytics();
  const {t} = useTranslation();
  const filtered = filterEntities(entityType, entities);
  if (filtered.length == 0) {
    return null;
  }

  const timeAgoFormatter = (value: number, unit: string, suffix: string) => {
    if (unit !== "second") {
      return [value, unit + (value !== 1 ? "s" : ""), suffix].join(" ");
    }

    if (suffix === "ago") {
      return "a few seconds ago";
    }
  };

  return (
    <TabBody name={"homepage-tab"} qualifier={hash}>
      {filtered.map(ewt => (
        <EntityCard
          id={`homepage-entity-card-${ewt.summary.id}`}
          key={ewt.summary.id}
          entity={ewt.summary}
          className={"homepage-entity-card"}
          onClick={event => {
            productEvent({
              name: `${t(`analytics.event-name.${sectionName}`, {
                lng: "en"
              })}_selected`,
              properties: {
                location: "Home Page",
                value: `${ewt.summary.id}`,
                result: `${t(
                  `analytics.result.${sectionName}`
                )} Entity Selected`
              }
            });
            navigateToEntityFromEvent(event, ewt.summary.id);
          }}
          footer={
            <div className={"homepage-entity-card-time"}>
              <ClockIcon className={"homepage-entity-card-time-icon"} />
              <span>{timeDescriptor}</span>
              <ReactTimeAgo date={ewt.timestamp} formatter={timeAgoFormatter} />
            </div>
          }
        />
      ))}
    </TabBody>
  );
};

const filterEntities = (
  entityType: EntityType | undefined,
  entities: EntityWithTimestamp[]
): EntityWithTimestamp[] => {
  if (entityType) {
    return entities.filter(ewt => {
      return entityType === ewt.summary.entityType;
    });
  } else return entities;
};
