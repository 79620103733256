import {FC} from "react";
import {useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {SearchPageState} from "../SearchPageState";
import {useSidebarItems} from "../../hooks";
import {useFlags} from "launchdarkly-react-client-sdk";
import {PageTemplate} from "../../layout";
import {BackToButton, Pages} from "../../back-buttons";
import {OldCommonOrgEntitySearch} from "./OldCommonOrgEntitySearch";
import {EntitySourceType} from "../../entities";

export const OldCommonOrgSearchPage: FC = () => {
  const location = useLocation<SearchPageState>();
  const query = location.search.match(/query=/i)
    ? new URLSearchParams(location.search).get("query") || ""
    : location.state?.query || "";
  const sidebarItems = useSidebarItems("search");
  const flags = useFlags();
  const {t} = useTranslation();

  return (
    <PageTemplate
      id={"search-page"}
      title={"Search Page"}
      breadcrumbs={<BackToButton page={Pages.Home} />}
      sidebarItems={sidebarItems}>
      <OldCommonOrgEntitySearch
        query={query}
        entitySourceType={EntitySourceType.OPERATIONAL}
      />
    </PageTemplate>
  );
};
