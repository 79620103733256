import {FC} from "react";

import {useTranslation} from "react-i18next";
import {features} from "../features";
import {useFlags} from "launchdarkly-react-client-sdk";
import {OperationalSearchPage} from "../entities/operational-hierarchy/search/OperationalSearchPage";
import {OldCommonOrgSearchPage} from "./old-common-org-search/OldCommonOrgSearchPage";

export const SearchPageSelector: FC = () => {
  const flags = useFlags();
  const {t} = useTranslation();
  if (flags[features.pdt.oe.viewOperationalEntity]) {
    return <OperationalSearchPage />;
  } else {
    return <OldCommonOrgSearchPage />;
  }
};
