import {FC, PropsWithChildren} from "react";
import {
  createSearchStateContext,
  SearchStateProviderProps,
  useDefaultSearchState
} from "../context";

// TODO: Remove this file once real OE search is implemented.

export const OldCommonOrgSearchStateContext = createSearchStateContext();

export const OldCommonOrgSearchStateProvider: FC<
  PropsWithChildren<SearchStateProviderProps>
> = ({query, type, table, result, children}) => {
  return (
    <OldCommonOrgSearchStateContext.Provider
      value={useDefaultSearchState(query, type, table, result)}>
      {children}
    </OldCommonOrgSearchStateContext.Provider>
  );
};
