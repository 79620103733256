/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useEffect, useState} from "react";
import {Favorite} from "../entities/favorites";
import {
  EntitySummary,
  SummaryResponse,
  LookupOldCommonOrgEntitySummaries
} from "../entities/backend";
import {HashTabs} from "../hash-tabs";
import {HomePageTab} from "./HomePageTab";
import {EntityType} from "@common-core/coat-operational-hierarchy-appsync-model";
import {ArrowsUpDownIcon} from "@interstate/components/Icons";
import {SplitButton} from "@interstate/components/SplitButton";
import {useStickyState} from "../hooks";
import "./HomePageTabSection.scss";
import {useTranslation} from "react-i18next";
import {useAnalytics} from "@common-core/react-analytics";
import {hashToEntityType} from "../search/EntityTabMapper";
import {useQuery} from "@apollo/client";
import {Endpoints} from "../runtime";

export interface EntityWithTimestamp {
  id: string;
  timestamp: number;
  summary: EntitySummary;
}

export interface HomePageTabSectionProps {
  id: string;
  title: string;
  favorites: Favorite[];
  emptyText: string;
  timeDescriptor: string;
  sectionName: string;
}

enum EntitySort {
  name = "name",
  time = "time"
}

export const HomePageTabSection: FC<HomePageTabSectionProps> = ({
  id,
  title,
  favorites,
  sectionName,
  timeDescriptor
}) => {
  const {t} = useTranslation();
  const {productEvent} = useAnalytics();
  const {loading, error, data} = useQuery<SummaryResponse>(
    LookupOldCommonOrgEntitySummaries,
    {
      variables: {ids: favorites.map((fav: Favorite) => fav.id)},
      context: {
        endpoint: Endpoints.BACKEND
      }
    }
  );
  const [timestampedEntities, setTimestampedEntities] = useState<
    EntityWithTimestamp[]
  >([]);
  const [entitySort, setEntitySort] = useStickyState<EntitySort>(
    EntitySort.time,
    title
  );

  const updateEntitiesWithTimestamps = async (
    favs: Favorite[],
    summaries: EntitySummary[]
  ) => {
    const timeStampedEntities: EntityWithTimestamp[] = [];
    summaries.forEach(e => {
      const currentFav: Favorite | undefined = favorites.find(
        fav => fav.id === e.id
      );
      if (currentFav !== undefined) {
        timeStampedEntities.push({
          id: currentFav.id,
          timestamp: currentFav.ts,
          summary: e
        });
      }
    });
    return timeStampedEntities;
  };

  const updateSort = (value: EntitySort) => {
    setEntitySort(value);
    productEvent({
      name: `${t(`analytics.event-name.${sectionName}`, {lng: "en"})}_sorted`,
      properties: {
        value: `${value}`,
        location: "Home Page",
        result: `${t(`analytics.result.${sectionName}`)} Sorted`
      }
    });
  };

  const sortEntities = (
    entities: EntityWithTimestamp[]
  ): EntityWithTimestamp[] => {
    if (entitySort === EntitySort.name.valueOf()) {
      return entities.sort((entityOne, entityTwo) =>
        entityOne.summary.dealerInformation.dbaName.localeCompare(
          entityTwo.summary.dealerInformation.dbaName
        )
      );
    }
    if (entitySort === EntitySort.time.valueOf()) {
      return entities.sort(
        (entityOne, entityTwo) => entityTwo.timestamp - entityOne.timestamp
      );
    }
    return entities;
  };

  useEffect(() => {
    if (data) {
      updateEntitiesWithTimestamps(favorites, data.summaries).then(
        entitiesWithTimestamps =>
          setTimestampedEntities(sortEntities(entitiesWithTimestamps))
      );
    }
  }, [data, entitySort]);

  return (
    <section
      className={"homepage-tab-section"}
      id={`homepage-tab-section-${sectionName}`}>
      <hgroup className={"homepage-tab-section-headers"}>
        <h2>{title}</h2>
        <SplitButton
          id={`sort-menu-${sectionName}`}
          data-testid={`sort-menu-${sectionName}`}
          icon={<ArrowsUpDownIcon />}
          size={"small"}
          buttonStyle={"secondary"}
          options={[
            {
              id: `sort-header-${sectionName}`,
              value: "Sort",
              label: t("homepage.sort.title") || "???",
              divider: true,
              disabled: true
            },
            {
              id: `sort-by-time-${sectionName}`,
              value: EntitySort.time,
              label: timeDescriptor,
              onSelect: () => updateSort(EntitySort.time)
            },
            {
              id: `sort-by-name-${sectionName}`,
              value: EntitySort.name,
              label: t("homepage.sort.name") || "???",
              onSelect: () => updateSort(EntitySort.name)
            }
          ]}>
          <span className={"sort-cards-by"}>
            {entitySort === EntitySort.time
              ? timeDescriptor
              : t("homepage.sort.name")}
          </span>
        </SplitButton>
      </hgroup>
      <HashTabs
        name={sectionName}
        id={id}
        initial={"all-results"}
        onTabActivated={({tab}) => {
          productEvent({
            name: "types_filtered",
            properties: {
              location: "Home Page",
              value: t(
                `entity-detail.types.${hashToEntityType(tab)?.toLowerCase()}`,
                {lng: "en"}
              ),
              result: `${t(`analytics.result.${sectionName}`)} Filtered`
            }
          });
        }}>
        <HomePageTab
          hash={"all-results"}
          label={t("entity-types.all-results")}
          entities={timestampedEntities}
          timeDescriptor={`${timeDescriptor}: `}
          sectionName={sectionName}
        />
        <HomePageTab
          hash={"dealerships"}
          label={t("entity-types.dealerships")}
          entityType={EntityType.ENTITY}
          entities={timestampedEntities}
          timeDescriptor={`${timeDescriptor}: `}
          sectionName={sectionName}
        />
        <HomePageTab
          hash={"single-dealers"}
          label={t("entity-types.single-dealers")}
          entityType={EntityType.STANDALONE_ENTITY}
          entities={timestampedEntities}
          timeDescriptor={`${timeDescriptor}: `}
          sectionName={sectionName}
        />
        <HomePageTab
          hash={"dealer-groups"}
          label={t("entity-types.dealer-groups")}
          entityType={EntityType.GROUP}
          entities={timestampedEntities}
          timeDescriptor={`${timeDescriptor}: `}
          sectionName={sectionName}
        />
        <HomePageTab
          hash={"sub-groups"}
          label={t("entity-types.sub-groups")}
          entityType={EntityType.SUB_GROUP}
          entities={timestampedEntities}
          timeDescriptor={timeDescriptor}
          sectionName={sectionName}
        />
      </HashTabs>
    </section>
  );
};
