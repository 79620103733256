import {TabBody} from "../../../tab-content";
import {HashTab} from "../../../hash-tabs";
import "./OperationalOverviewTab.scss";
import {useOperationalEntity} from "../../context";
import {OperationalDetailsColumn} from "./OperationalDetailsColumn";
import {useRecentlyViewed} from "../../../hooks";
import {useEffect} from "react";
import {OperationalBoidsColumn} from "../../boids";

export const OperationalOverviewTab: HashTab = () => {
  const {
    entity: {id}
  } = useOperationalEntity();
  const {addBrowsedDealer} = useRecentlyViewed();
  useEffect(() => addBrowsedDealer(id), [id]);
  return (
    <TabBody name={"overview"}>
      <OperationalDetailsColumn />
      <OperationalBoidsColumn />
    </TabBody>
  );
};
