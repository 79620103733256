import {FC, PropsWithChildren} from "react";
import {OldCommonOrgEntity} from "../model";
import {OldCommonOrgEntityContext} from "./OldCommonOrgEntityContext";

export interface OldCommonOrgEntityProviderProps {
  entity: OldCommonOrgEntity;
}

export const OldCommonOrgEntityProvider: FC<
  PropsWithChildren<OldCommonOrgEntityProviderProps>
> = ({entity, children}) => {
  return (
    <OldCommonOrgEntityContext.Provider value={{entity}}>
      {children}
    </OldCommonOrgEntityContext.Provider>
  );
};
