import {EntityType} from "@common-core/coat-operational-hierarchy-appsync-model";

const map: Record<string, EntityType | undefined> = {
  "all-results": undefined,
  "dealerships": EntityType.ENTITY,
  "single-dealers": EntityType.STANDALONE_ENTITY,
  "dealer-groups": EntityType.GROUP,
  "sub-groups": EntityType.SUB_GROUP
};

export function hashToEntityType(hash: string): EntityType | undefined {
  const entry = Object.entries(map).find(([key, value]) => key === hash);
  return entry ? entry[1] : undefined;
}

export function entityTypeToHash(type: EntityType | undefined): string {
  const entry = Object.entries(map).find(([key, value]) => value === type);
  return entry ? entry[0] : "all-results";
}
