import {FC} from "react";
import {useTranslation} from "react-i18next";
import {useAnalytics} from "@common-core/react-analytics";
import {Badge} from "@interstate/components/Badge";
import {
  Accordion,
  AccordionGroup,
  AccordionDetails,
  AccordionSummary
} from "@interstate/components/AccordionGroup";
import {TabColumn, TabRow} from "../../tab-content";
import {LinkedSolutions, Solution, solutions} from "../model";
import "./Solutions.scss";

interface LabeledSolution {
  label: string;
  solution: Solution;
}

export const Solutions: FC<{linkedSolutions: LinkedSolutions}> = ({
  linkedSolutions
}) => {
  const {t} = useTranslation();
  const {productEvent} = useAnalytics();
  const toLabeledSolution = (solution: Solution): LabeledSolution => {
    return {
      label: t(`entity-detail.solutions.${solution}`),
      solution
    };
  };

  const toAccordion = ({label, solution}: LabeledSolution) => {
    // see https://github.com/apollographql/apollo-client/issues/5903
    const boids = [...linkedSolutions[solution]];
    const empty = boids.length === 0;
    return (
      <Accordion
        key={solution}
        id={`solution-accordion-item-${solution}`}
        data-testid={`solution-accordion-item-${solution}`}
        className={"solution-accordion-item"}
        onChange={() => {
          productEvent({
            name: "boid_clicked",
            properties: {
              location: "Dealer Details Overview",
              value: `${label}`,
              result: `BOIDS for ${label} Displayed`
            }
          });
        }}>
        <AccordionSummary
          badge={
            <Badge
              id={`solution-${solution}-badge`}
              // Badges tack on the -badge to the id
              data-testid={`solution-${solution}`}
              className={"solution-badge"}
              variant={empty ? "light" : "success"}>
              {boids.length}
            </Badge>
          }>
          <div
            id={`solution-${solution}-header`}
            data-testid={`solution-${solution}-header`}
            className={"solution-header"}>
            <span className={"solution-name"}>{label}</span>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          {!empty ? (
            <ul>
              {boids
                .sort((a: string, b: string) => a.localeCompare(b))
                .map(boid => (
                  <li key={boid}>{boid}</li>
                ))}
            </ul>
          ) : (
            <span className={"no-links"}>
              {t("entity-detail.solutions.no-links", {solution: label})}
            </span>
          )}
        </AccordionDetails>
      </Accordion>
    );
  };

  return (
    <TabColumn
      name={"solutions"}
      header={<h3>{t("entity-detail.business-operations-ids")}</h3>}>
      <TabRow name={"solutions"}>
        <AccordionGroup
          id="solutions-accordion"
          accordionType={"multi-expanded"}
          className={"solution-accordion"}
          contained={true}>
          {solutions()
            .map(toLabeledSolution)
            .sort((a: LabeledSolution, b: LabeledSolution) =>
              a.label.localeCompare(b.label)
            )
            .map(toAccordion)}
        </AccordionGroup>
      </TabRow>
    </TabColumn>
  );
};
