import {FC} from "react";
import {TabRow} from "../../../tab-content";
import {EntityStatusBar} from "../../badges";
import {EntityStatus} from "@common-core/coat-operational-hierarchy-appsync-model";
import {EntitySourceType} from "../../EntitySourceType";

interface StatusBarProps {
  entitySourceType: EntitySourceType;
  statuses: EntityStatus[];
  id: string;
}
export const OldCommonOrgDealerStatusBar: FC<StatusBarProps> = ({
  id,
  statuses,
  entitySourceType
}) => {
  return (
    <TabRow name={"dealer-status-bar"}>
      <EntityStatusBar
        id={id}
        statuses={filterStatuses(statuses, entitySourceType) || []}
        compact={false}
      />
    </TabRow>
  );
};
const filterStatuses = (
  statuses: EntityStatus[],
  entitySourceType: EntitySourceType
) => {
  return statuses;
};
