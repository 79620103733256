import {FC, useState} from "react";
import {PageTemplate} from "../layout";

import "./HomePage.scss";
import {useNavigation, useSidebarItems} from "../hooks";
import {FavoritesTabSection} from "./FavoritesSection";
import {RecentlyViewedTabSection} from "./RecentlyViewedSection";
import {SearchBar} from "../search/search-bar";

export const HomePage: FC<{}> = () => {
  const [quickSearchValue, setQuickSearchValue] = useState("");
  const navigation = useNavigation();
  const sidebarItems = useSidebarItems("home");

  const submitSearch = (event: Event, query: string) => {
    navigation.navigateToSearch(event, query);
  };

  return (
    <PageTemplate
      id={"home-page"}
      title={"Home Page"}
      sidebarItems={sidebarItems}>
      <div className={"quick-search-box-container"}>
        <SearchBar
          id={"quick-search-box"}
          query={quickSearchValue}
          onQueryChange={setQuickSearchValue}
          onSearch={submitSearch}
          analyticsLocation={"home-page"}
        />
      </div>
      <div className={"page-content"}>
        <RecentlyViewedTabSection />
        <FavoritesTabSection />
      </div>
    </PageTemplate>
  );
};
