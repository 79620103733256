import {useTranslation} from "react-i18next";
import {TableColumn} from "@interstate/components/Table";
import {statesMapping} from "../../utils/dataMappers";
import {TableData} from "./TableData";
import {entityTypeKey} from "@common-core/coat-operational-hierarchy-appsync-model";
import {EntityStatusBar} from "../../entities/badges";

export function useOldCommonOrgTableColumns(): TableColumn[] {
  const {t} = useTranslation();
  const columns: TableColumn[] = [
    {
      title: t("table.columns.name"),
      dataIndex: "name",
      className: "entity-name",
      columnSortable: true,
      sorter: (a: TableData, b: TableData) => a.name.localeCompare(b.name)
    },
    {
      title: t("table.columns.city"),
      dataIndex: "city",
      className: "entity-city",
      columnSortable: true,
      sorter: (a: TableData, b: TableData) => a.city.localeCompare(b.city)
    },
    {
      title: t("table.columns.state"),
      dataIndex: "state",
      className: "entity-state",
      columnSortable: true,
      sorter: (a: TableData, b: TableData) => a.state.localeCompare(b.state),
      render: (text: string, record: TableData) => {
        const state = record.state;
        return statesMapping[state] || state;
      }
    },
    {
      title: t("table.columns.oems"),
      dataIndex: "oems",
      className: "entity-oems",
      render: (text: string, record: TableData) => {
        const oems = record.oems;
        return oems.map(oem => oem.nameplateDescription).join(", ");
      }
    },
    {
      title: t("table.columns.solutions"),
      dataIndex: "solutions",
      className: "entity-solutions",
      columnSortable: false,
      render: (text: string, record: TableData) => {
        const solutions = record.solutions;
        return Object.keys(solutions)
          .filter(solution => solutions[solution].length > 0)
          .map(solution => t(`entity-detail.solutions.${solution}`))
          .join(", ");
      }
    },
    {
      title: t("table.columns.entity-type"),
      dataIndex: "type",
      className: "entity-classification",
      columnSortable: true,
      sorter: (a: TableData, b: TableData) => a.type.localeCompare(b.type),
      render: (text: string, record: TableData) => {
        return t(`entity-detail.types.${entityTypeKey(record.type)}`);
      }
    },
    {
      title: t("table.columns.status"),
      key: "status",
      className: "entity-status",
      columnSortable: false,
      render: (text: string, record: TableData) => (
        <EntityStatusBar id={record.key} statuses={record.statuses} />
      )
    }
  ];
  return columns;
}
