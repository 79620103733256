export enum Solution {
  fus = "fus",
  fni = "fni",
  ddc = "ddc",
  dms = "dms",
  hmn = "hmn",
  vat = "vat",
  xti = "xti",
  atc = "atc",
  dt = "dt",
  rts = "rts",
  ico = "ico",
  vin = "vin",
  man = "man",
  mmd = "mmd",
  esn = "esn",
  fdc = "fdc",
  fgc = "fgc"
}

const values = Object.values(Solution);

export function solutions(): Solution[] {
  // prettier-ignore
  return values
    .sort();
}

export function solution(alias: string): Solution | null {
  const normalized = alias?.toLowerCase();
  return values.find(solution => solution.valueOf() === normalized) || null;
}
