import {FC, useState} from "react";
import {useTranslation} from "react-i18next";
import {useAnalytics} from "@common-core/react-analytics";
import {
  SimpleTable,
  TableBody,
  TableRow,
  TableCell
} from "@interstate/components/SimpleTable";
import {Button} from "@interstate/components/Button";
import {Popover} from "@interstate/components/Popover";

import "./OperationalBusinessInformation.scss";
import {useOperationalEntity} from "../../context";
import {useNavigation} from "../../../hooks";
import {TabRow} from "../../../tab-content";
import {EntityCard} from "../../card";
import {
  ActionButtons,
  CopyButton,
  EditButton,
  InlineButtonPlaceholder
} from "../../../action-buttons";
import {EditParentModal} from "./EditParentModal";
import {OnCompleteCallback} from "../../../utils";

export const OperationalBusinessInformation: FC = () => {
  const {t} = useTranslation();
  const {
    entity: {id, parent, organizationInformation}
  } = useOperationalEntity();
  const {navigateToEntityFromEvent} = useNavigation();
  const {productEvent} = useAnalytics();
  const [editingParent, setParentEditing] = useState<boolean>(false);
  const finishParentEditing: OnCompleteCallback = () => {
    setParentEditing(false);
  };
  return (
    <TabRow
      name={"business-information"}
      header={<h4>{t("entity-detail.dealer.business-information")}</h4>}>
      {editingParent && (
        <EditParentModal
          id={id}
          currentParent={parent?.id}
          onComplete={finishParentEditing}
        />
      )}
      <SimpleTable
        id={`business-information-table-${id}`}
        data-testid={`business-information-table-${id}`}
        dataDensity={"small"}
        background={"white"}
        className={"business-information-table"}
        bordered={false}
        hover={false}
        striped={false}>
        <TableBody>
          <TableRow>
            <TableCell className={"information-field"}>
              {t("entity-detail.dealer.legal-name")}
            </TableCell>
            <TableCell
              className={"information-value"}
              id={"dealer-information-legal-name"}>
              {organizationInformation?.legalName}
            </TableCell>
            <TableCell className={"information-actions"} />
          </TableRow>
          <TableRow>
            <TableCell className={"information-field"}>
              {t("entity-detail.common-org-id")}
            </TableCell>
            <TableCell
              className={"information-value"}
              id={"dealer-information-common-org-id"}>
              {id}
            </TableCell>
            <TableCell className={"information-actions"}>
              <ActionButtons>
                <CopyButton
                  content={id}
                  qualifier={"common-org-id"}
                  item={t("entity-detail.common-org-id")}
                  inline={true}
                />
                <InlineButtonPlaceholder />
              </ActionButtons>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={"information-field"}>
              {t("entity-detail.parent-id")}
            </TableCell>
            <TableCell
              className={"information-value"}
              id={"dealer-information-parent-id"}
              data-testid={"dealer-information-parent-id"}>
              {parent ? (
                <Popover
                  id={"entity-parent-popover"}
                  data-testid={"entity-parent-popover"}
                  trigger={"outsideClick"}
                  position={"bottom"}
                  popoverContent={
                    <EntityCard
                      id={"entity-card-parent"}
                      className={"popover-entity-card"}
                      heading={"h4"}
                      entity={parent}
                      footer={
                        <Button
                          id={`entity-card-button-${parent.id}`}
                          className={`entity-card-button-parent`}
                          buttonStyle={"tertiary"}
                          onClick={e => {
                            e.preventDefault();
                            // Make TS inspector happy
                            if (parent) {
                              navigateToEntityFromEvent(e, parent.id);
                            }
                          }}>
                          {t("entity-detail.view-parent")}
                        </Button>
                      }
                    />
                  }>
                  <span
                    className={"entity-parent"}
                    onClick={() => {
                      productEvent({
                        name: "parent_clicked",
                        properties: {
                          location: "Dealer Details Overview",
                          value: `${parent?.id}`,
                          result: "Parent Displayed"
                        }
                      });
                    }}>
                    {parent.id}
                  </span>
                </Popover>
              ) : (
                t("entity-detail.no-parent")
              )}
            </TableCell>
            <TableCell className={"information-actions"}>
              <ActionButtons>
                {parent?.id ? (
                  <CopyButton
                    content={parent.id}
                    qualifier={"parent-id"}
                    item={t("entity-detail.parent-id")}
                    inline={true}
                  />
                ) : (
                  <InlineButtonPlaceholder />
                )}
                <EditButton
                  onClick={() => setParentEditing(true)}
                  qualifier={"parent-id"}
                  tooltip={t("entity-detail.edit-parent-id")}
                  inline={true}
                />
              </ActionButtons>
            </TableCell>
          </TableRow>
        </TableBody>
      </SimpleTable>
    </TabRow>
  );
};
