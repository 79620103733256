import {Pagination, Sort, TableState} from "./context";
import {Dispatch, SetStateAction} from "react";
import {OperationalSearchResult, SearchResult} from "../entities/model";
import {ClientEntity} from "@common-core/coat-operational-hierarchy-appsync-model";

export const saveTablePaginationState = (
  pagination: Pagination,
  tableStateSetter: Dispatch<SetStateAction<TableState>>
): void => {
  tableStateSetter((current: TableState) => {
    return {
      ...current,
      pagination
    };
  });
};

export const saveTableSortState = (
  sort: Sort,
  setTableState: Dispatch<SetStateAction<TableState>>
): void => {
  setTableState((current: TableState) => {
    return {
      ...current,
      sort
    };
  });
};

export const hasSearchResults = (
  searchResult: SearchResult | undefined
): boolean => {
  return !!searchResult && searchResult.results.length > 0;
};

export const hasOperationalSearchResults = (
  searchResult: OperationalSearchResult | undefined
): boolean => {
  return !!searchResult && searchResult.results.length > 0;
};

export const hasClientHierarchySearchResults = (
  searchResult: ClientEntity[] | undefined
): boolean => {
  return !!searchResult && searchResult.length > 0;
};
