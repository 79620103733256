import {FC, PropsWithChildren, useCallback, useEffect} from "react";
import {useLocation, useParams} from "react-router-dom";
import {useEnvironment} from "@common-core/react-runtime/context";
import {useAnalytics} from "@common-core/react-analytics";
import {BackendError} from "@common-core/runtime-js/backend";
import {useQuery} from "@apollo/client";

import {useRecentlyViewed, useSidebarItems} from "../../hooks";
import {BackToButton, Pages} from "../../back-buttons";
import {EntityResponse, LookupOldCommonOrgEntity} from "../backend";
import {OldCommonOrgEntity} from "../model";
import {Endpoints, Environment} from "../../runtime";
import {ErrorPage, PageTemplate} from "../../layout";
import {ActivityIndicator} from "../../activity-indicator";
import {OldCommonOrgEntityProvider} from "../context";
import {FavoriteButton} from "../favorites";
import {useTranslation} from "react-i18next";
import {HashTabs} from "../../hash-tabs";
import {useFlags} from "launchdarkly-react-client-sdk";
import {EmbeddedComponentTab} from "../embedded";
import {OldCommonOrgHierarchyTab} from "./hierarchy-tab";
import {OldOverviewTab} from "./overview-tab";
import {EntitySourceType} from "../EntitySourceType";

interface RouteParams {
  id: string;
}

interface LocationState {
  from: string;
  query: string;
}

export const OldCommonOrgEntityPage: FC<PropsWithChildren<{}>> = ({
  children
}) => {
  const {id} = useParams<RouteParams>();
  const environment = useEnvironment<Environment>();
  const sidebarItems = useSidebarItems();
  const {productEvent} = useAnalytics();
  const {t} = useTranslation();
  const location = useLocation<LocationState>();
  const {addBrowsedDealer} = useRecentlyViewed();
  const flags = useFlags();
  const backToPage =
    location.state?.from === "/search" ? Pages.Search : Pages.Home;
  const {loading, error, data} = useQuery<EntityResponse<OldCommonOrgEntity>>(
    LookupOldCommonOrgEntity,
    {
      variables: {id},
      context: {
        endpoint: Endpoints.BACKEND
      }
    }
  );
  const translate = useCallback(
    (tab: string) => {
      return tab === "hierarchy" || tab === "overview"
        ? t(`entity-detail.${tab}`, {lng: "en"})
        : t(`entity-tabs.embedded-components.${tab}`, {lng: "en"});
    },
    [t]
  );
  const entity = data?.entity || ({} as OldCommonOrgEntity);
  useEffect(() => {
    if (entity.id) {
      addBrowsedDealer(entity.id);
    }
  }, [entity]);
  if (error) {
    return <ErrorPage error={error} logo={false} sidebarItems={sidebarItems} />;
  }
  if (loading) {
    return (
      <PageTemplate
        id={"coe-operational-entity-page"}
        title={"Common Org Entity Page"}
        sidebarItems={sidebarItems}>
        <ActivityIndicator />
      </PageTemplate>
    );
  }
  if (!loading && data?.entity === undefined) {
    return (
      <ErrorPage
        error={new BackendError("Entity not found", 404)}
        logo={false}
        sidebarItems={sidebarItems}
      />
    );
  }
  return (
    <OldCommonOrgEntityProvider entity={entity}>
      <PageTemplate
        id={"coe-operational-entity-page"}
        title={"Client Entity Page"}
        breadcrumbs={<BackToButton page={backToPage} />}
        sidebarItems={sidebarItems}>
        <div className={"entity-detail"} data-testid={"entity-detail"}>
          <header className={"entity-detail-header"}>
            <h2 className={"entity-business-name"}>
              {entity.dealerInformation.dbaName}
            </h2>
            <FavoriteButton />
          </header>
          <HashTabs
            id={"entity-detail-tabs"}
            initial={"overview"}
            onTabActivated={event => {
              productEvent({
                name: `entity_detail_tab_clicked`,
                properties: {
                  location: "Dealer Details Overview",
                  value: `${translate(event.tab)}`,
                  result: "Tab Displayed"
                }
              });
            }}>
            <OldOverviewTab
              entitySourceType={EntitySourceType.OLD}
              displayHistory={true}
              hash={"overview"}
              label={t("entity-tabs.overview").toString()}
            />
            <OldCommonOrgHierarchyTab
              hash={"hierarchy"}
              label={t("entity-tabs.hierarchy")}
            />
            {environment.tabComponents
              .filter(component => {
                return component.featureFlag
                  ? flags[component.featureFlag]
                  : true;
              })
              .map(component => (
                <EmbeddedComponentTab
                  key={component.element}
                  hash={component.hash || component.element}
                  label={t(
                    `entity-tabs.embedded-components.${component.element}`
                  )}
                  definition={component}
                  entity={entity.id}
                />
              ))}
          </HashTabs>
        </div>
      </PageTemplate>
    </OldCommonOrgEntityProvider>
  );
};
