import {TabBody} from "../../../tab-content";
import {HashTab} from "../../../hash-tabs";
import {OldCommonOrgHierarchyColumn} from "./OldCommonOrgHierarchyColumn";

export const OldCommonOrgHierarchyTab: HashTab = () => {
  return (
    <TabBody name={"hierarchy-tab"}>
      <OldCommonOrgHierarchyColumn />
    </TabBody>
  );
};
