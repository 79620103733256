export const features = {
  ops: {
    runtimeInspectorVisible: "ops.runtime-control.inspector",
    apolloClientConnectToDevTools: "ops.apollo-client.connect-to-devtools"
  },
  pdt: {
    oe: {
      viewOperationalEntity: "pdt.oe.view-operational-entity"
    },
    permission: {
      viewClientEntity: "pdt.permission.client-entity.view",
      createOperationalEntity: "pdt.permission.operational-entity.create",
      createComplexEntities:
        "pdt.permission.operational-entity.create-complex-entities"
    },
    pendo: {
      scriptInjectionEnabled: "pdt.pendo-script-injection"
    }
  },
  dev: {
    unitTestFlag: "dev.unit-test-flag",
    displayCustomerImports: "dev.display-customer-imports",
    components: {
      displayDealerUserSearch: "dev.components.display-dealer-user-search"
    }
  }
};
